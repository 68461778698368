<template>
  <div id="california-privacy-rights">
    <div class="close" @click="$emit('close')">
      <img src="@/assets/delete.png" alt="close" />
    </div>

    <h1>California Privacy Rights</h1>
    <h3>Requests must be made by current residents of California</h3>

    <form novalidate @submit.prevent="submit">
      <InputField
        name="full_name"
        :value="user.full_name"
        @update:value="user.full_name = $event"
        label="Full Name"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="street_address"
        :value="user.street_address"
        @update:value="user.street_address = $event"
        label="Street Address"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="second_street_address"
        :value="user.second_street_address"
        @update:value="user.second_street_address = $event"
        label="Second Street Address"
        :required="false"
        :errors="errors"
      />

      <InputField
        name="city"
        :value="user.city"
        @update:value="user.city = $event"
        label="City"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="state"
        :value="user.state"
        @update:value="user.state = $event"
        label="State"
        :disabled="true"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="postal_code"
        :value="user.postal_code"
        @update:value="user.postal_code = $event"
        label="Postal Code"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="country"
        :value="user.country"
        @update:value="user.country = $event"
        label="Country"
        :required="true"
        :disabled="true"
        :errors="errors"
      />

      <InputField
        name="telephone"
        :value="user.telephone"
        @update:value="user.telephone = $event"
        label="Telephone"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="email_address"
        :value="user.email_address"
        @update:value="user.email_address = $event"
        label="Email Address"
        :required="true"
        :errors="errors"
      />

      <InputField
        name="publisher_number"
        :value="user.publisher_number"
        @update:value="user.publisher_number = $event"
        label="P# (HFA Publisher Number)"
        :required="false"
        :errors="errors"
      />

      <div class="related-information">
        <strong>I want information related to:</strong>

        <ul>
          <li v-for="(info, index) in related_info" :key="index">
            <label :for="`related_info-${index}`">
              <input
                type="checkbox"
                :id="`related_info-${index}`"
                name="related_info[]"
                :value="info"
                v-model="user.related_info"
              />
              {{ info }}
            </label>
          </li>
        </ul>
      </div>

      <button type="submit" class="button primary">Submit</button>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import InputField from "@/components/InputField.vue";

export default {
  name: "CaliforniaPrivacyRights",
  components: {
    InputField
  },
  data: function() {
    return {
      errors: [],
      user: {
        full_name: null,
        street_address: null,
        second_street_address: null,
        city: null,
        state: "CA",
        postal_code: null,
        country: "USA",
        telephone: null,
        email_address: null,
        publisher_number: null,
        related_info: []
      },
      related_info: [
        "The categories of personal information it has collected about me.",
        "The categories of sources from which my personal information is collected.",
        "The business or commercial purpose for collecting my personal information.",
        "The categories of third parties with whom the business shares personal information.",
        "The specific pieces of personal information it has collected about that consumer"
      ]
    };
  },
  methods: {
    submit: function() {
      this.errors = [];
      const fields = Object.keys(this.user);

      for (const field of fields) {
        const value = this.user[field];

        if (
          field == "second_street_address" ||
          field == "related_info" ||
          field == "publisher_number"
        ) {
          continue;
        }

        if (!value) {
          this.errors.push({ field: field, error: "Field Required" });
        }

        if (
          field == "email_address" &&
          !this.errors.find(error => error.field == "email_address")
        ) {
          let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!reg.test(value)) {
            this.errors.push({ field: field, error: "Email is invalid" });
          }
        }
      }

      if (!this.errors.length) {
        // console.log(JSON.stringify(this.user));
        // this.$toastr.s("Your email was sent.");
        // this.$emit("close");
        const url = process.env.VUE_APP_API_URL.replace(
          "users",
          "contact/legal"
        );
        axios.post(url, this.user).then(() => {
          this.$toastr.s("Your email was sent.");
          this.$emit("close");
        });
      }
    }
  }
};
</script>
<style lang="scss">
.v--modal-overlay .v--modal-background-click {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v--modal-overlay .v--modal-box.california-form-modal {
  overflow: auto !important;
  height: 90vh !important;
  // width: auto !important;
  /* top: initial !important;
  left: initial !important; */
}

#california-privacy-rights {
  font-family: "Abel", sans-serif;
  padding: 20px;
  position: relative;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;

    img {
      width: 25px;
      height: 25px;
    }
  }

  h1 {
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}
</style>
