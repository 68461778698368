<template>
  <div id="privacy-policy">
    <Navbar />

    <div class="content">
      <h1 class="page-title">PRIVACY POLICY - YOUR PRIVACY RIGHTS</h1>

      <div class="last-updated">Last Updated on May 3, 2024</div>

      <h2>HFA Online Privacy Policy</h2>

      <p dir="ltr">
        The Harry Fox Agency LLC ("Company" or "we") is concerned about privacy
        issues and wants you to be familiar with how we collect, use and
        disclose information. This Privacy Policy describes our practices in
        connection with information that we collect through Company’s online
        websites and applications (collectively the "Platform"), including
        without limitation any successor site(s) and applications(s) thereto.
        You may contact the Company by mailing a letter to the address outlined
        in the Terms of Use or by submitting your inquiry via Company’s
        <a @click="showContactForm">Contact Form</a>, and any reference herein
        to Company’s Contact Page means the form linked above.
      </p>

      <p dir="ltr">
        By accessing or using the Platform, or providing information to us in
        connection with the Platform, you acknowledge, on behalf of yourself and
        any publisher, licensee or other entity that you may represent, that we
        may use and disclose such information in accordance with this Privacy
        Policy. If you object or do not consent to our use and disclosure of
        such information in accordance with this Privacy Policy, or you do not
        have the authority to make such acknowledgement on behalf of such
        entity, please do not provide such information on or through the
        Platform (which may mean that you will be unable to use the Platform).
      </p>
      <br />
      <p dir="ltr">
        This Privacy Policy is the sole authorized statement of our practices
        with respect to the collection, usage and disclosure of information
        through the Platform; any summaries of this Privacy Policy, including
        those generated by third-party software or otherwise (such as "Platform
        for Privacy Preferences" or "P3P") shall have no legal effect, are not
        binding upon us, do not supersede or modify this Privacy Policy, and
        shall not be relied upon in substitution for this Privacy Policy.
      </p>
      <br />
      <p dir="ltr">
        <b>A. Updates to This Privacy Policy.</b>
      </p>
      <br />
      <p dir="ltr">
        We may change this Privacy Policy at any time. Please take a look at the
        "Last Updated" legend at the top of this page to see when this Privacy
        Policy was last revised. Any changes to this Privacy Policy will become
        effective when we make the revised Privacy Policy available on or
        through the Platform. Your use of the Platform or the submission of any
        information through the Platform following such changes means that you
        accept the revised Privacy Policy.
      </p>
      <br />
      <p dir="ltr">
        <b>B. Third Parties.</b>
      </p>
      <br />
      <p dir="ltr">
        If you submit any information, including Personal Information, to any
        third party — for example, via a third-party software application or
        service, including any social media service, that is included in,
        available through or linked from the Platform — then such third party’s
        collection, use and disclosure of such information may be governed by
        its privacy policy, and will not be governed by our Privacy Policy. We
        recommend that you familiarize yourself with the privacy policies and
        practices of any such third parties.
      </p>
      <br />
      <p dir="ltr">
        THIS PRIVACY POLICY DOES NOT ADDRESS, AND WE ARE NOT RESPONSIBLE FOR,
        THE PRIVACY, INFORMATION OR OTHER PRACTICES OF ANY THIRD PARTY,
        INCLUDING ANY THIRD-PARTY SERVICE PROVIDERS, BUSINESS ASSOCIATES, AND
        ANY OTHER THIRD PARTY OPERATING ANY SOFTWARE APPLICATION OR WEBSITE THAT
        IS MADE AVAILABLE THROUGH THE PLATFORM OR TO WHICH THE PLATFORM CONTAINS
        A LINK. THE AVAILABILITY THROUGH THE PLATFORM OF ANY THIRD-PARTY
        SOFTWARE APPLICATION OR WEBSITE, OR THE INCLUSION ON THE PLATFORM OF ANY
        LINKS TO ANY SUCH APPLICATION OR WEBSITE, DOES NOT IMPLY ENDORSEMENT BY
        US OF THE APPLICABLE SOFTWARE APPLICATION OR WEBSITE.
      </p>
      <br />
      <p dir="ltr">
        <b>C. Jurisdictional Issues.</b>
      </p>
      <br />
      <p dir="ltr">
        The Platform is controlled by us from the United States; accordingly,
        this Privacy Policy, and our collection, use and disclosure of your
        information, is governed by the laws of the United States, and not by
        the laws of any country, territory or jurisdiction other than the United
        States. We do not represent or warrant that the Platform, or any
        functionality or feature thereof, is appropriate or available for use in
        any particular jurisdiction. Those who choose to download, access or use
        the Platform do so on their own initiative and at their own risk, and
        are responsible for complying with all local laws, rules and
        regulations. By downloading, accessing, using or submitting any
        information to the Platform, users from outside the United States
        acknowledge that the Platform is subject to laws of the United States,
        and consent to the transfer of their information to the United States
        and any other jurisdiction, which may provide a different level of data
        security than in their country of residence, and waive any claims that
        may arise under their own national laws.
      </p>
      <br />
      <p dir="ltr">
        <b>D. Collection, Use and Disclosure of Personal Information.</b>
      </p>
      <br />
      <p dir="ltr">
        Personal Information We May Collect
      </p>
      <br />
      <p dir="ltr">
        “Personal Information” is information that identifies someone, including
        EU, UK, and Swiss individuals, as a specific individual. In addition to
        Personal Information, Other Information (as defined below) may be
        collected in connection with the Platform; the collection, use and
        disclosure of Other Information is addressed separately below, under the
        “Other Information” heading.
      </p>
      <br />
      <p dir="ltr">
        We and our third-party service providers may collect the following
        Personal Information, including that of EU, UK, and Swiss individuals:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Name;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Home address;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Personal telephone number;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Personal e-mail address;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Social Security number;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Individual Taxpayer Identification numbers;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Personal bank account, credit card and debit card numbers;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Other information contained in the Interested Parties Information
            (“IPI”) System developed and maintained by SUISA, the Cooperative
            Society of Music Authors and Publishers in Switzerland. More
            information concerning the IPI can be found at
            <a href="https://www.ipisystem.org/" target="_blank"
              >http://www.ipisystem.org</a
            >, and;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Other personally identifiable information about you and third
            parties that you may choose to disclose (including by authorizing
            third parties to disclose) to Company and/or its third-party service
            providers, including by completing profile forms, completing
            applications, questionnaires or similar documents, communicating
            with customer service personnel, and otherwise interacting with
            Company and its third-party service providers.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        Certain Personal Information is voluntary and not required to access or
        use the Platform; other Personal Information may be mandatory and
        required to access or use the Platform or certain portions of the
        Platform.
      </p>
      <br />
      <p dir="ltr">
        IF YOU SUBMIT ANY PERSONAL INFORMATION OF OR RELATING TO A THIRD PARTY
        (INCLUDING ANY INDIVIDUALS ASSOCIATED WITH PUBLISHERS OR LICENSEES) TO
        US OR OUR THIRD-PARTY SERVICE PROVIDERS IN CONNECTION WITH THE PLATFORM,
        YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO DO SO AND TO PERMIT US TO
        USE AND DISCLOSE SUCH INFORMATION IN ACCORDANCE WITH THIS PRIVACY
        POLICY.
      </p>
      <br />
      <p dir="ltr">
        How We May Use Personal Information
      </p>
      <br />
      <p dir="ltr">
        We and our third-party service providers may use Personal Information,
        including that of EU, UK, and Swiss individuals:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            To respond to your inquiries and fulfill your requests, such as to
            send newsletters to you, process your applications, complete your
            commercial transactions and otherwise operate the Platform-related
            products and services and the businesses of Company and its related
            legal entities.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To send administrative information to you, including information
            regarding the Platform and changes to our terms, conditions and
            policies.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To facilitate business transactions, business relationships and the
            resolution of disputes and other issues by, within or among Company,
            Company’s related legal entities, publishers, licensees and others.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To allow you to send and receive messages and invitations to and
            from other individuals, through or in connection with the Platform,
            and to send and receive such messages and invitations on your
            behalf.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To help us improve the customer experience, including by learning
            more about your preferences to help personalize your experience with
            us.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To send marketing communications to you that we believe may be of
            interest to you, unless you opt-out in accordance with the Choices
            and Access section below.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            For our business, administrative and informational purposes,
            including but not limited to quality assessment, data analysis,
            audits, developing and improving the Platform and new products and
            services, identifying usage trends and determining the effectiveness
            of our promotional campaigns.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To protect the security or integrity of our businesses and Internet
            properties, including the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            As we believe to be necessary or appropriate: (a) under applicable
            law, including laws outside your country of residence; (b) to comply
            with legal process; (c) to respond to requests from public or
            government authorities, including public or government authorities
            outside your country of residence; (d) to enforce our terms and
            conditions; (e) to protect our operations or those of any of our
            related legal entities; (f) to protect our rights, privacy, safety
            or property, or that of our related legal entities, service
            providers, members, you or others, including through credit-fraud
            protection and risk reduction; (g) at our discretion under emergency
            circumstances; or (h) to allow us to pursue available remedies or
            limit the damages that we may sustain.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        How Personal Information May Be Disclosed
      </p>
      <br />
      <p dir="ltr">
        Personal Information, including that of EU, UK, and Swiss individuals, may be
        disclosed:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            To our third-party service providers that may provide services such
            as hosting of the Platform, data analysis, IT services and
            infrastructure, customer service, e-mail delivery, auditing,
            information and identity verification, background checks, credit
            checks, payment processing and other similar services. Although we
            seek to restrict third-party service providers from using any
            Personal Information received from Company or through the Platform
            for any purpose other than providing services for Company and the
            Platform, please note that Company is not responsible for the
            privacy practices of any third parties, including any negligence or
            misconduct by any third parties that receive your payment card or
            other information.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To identify you to anyone to whom you send messages or invitations
            through or in connection with the Platform, including messages and
            invitations that we may send on your behalf.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To facilitate business transactions, business relationships and the
            resolution of disputes and other issues by, within or among Company,
            Company’s related legal entities, publishers, licensees and others.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To our third-party clients to whom we provide licensing and royalty
            distribution administration services, including, for example, the
            mechanical licensing collective, as needed, in Company’s sole
            discretion, to support the clients’ operations or to facilitate
            business transactions.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To our related legal entities for all uses permitted under this
            Privacy Policy (including for such related legal entities to send
            marketing communications to you), unless you opt-out in accordance
            with the Choices and Access section below.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            By you, through messages, chats, message boards, forums, public
            profile pages, user directories and blogs and other functionality
            with which you are able to share or post information and materials.
            Please note that any information and materials that you post or
            disclose using such functionality will become public information,
            and may be available for other users of the Platform and the general
            public to access, use, alter, manipulate or disclose. We are not
            responsible for such access, use, alteration, manipulation or
            disclosure. We urge you to be very careful when deciding to disclose
            your Personal Information, or any other information, in connection
            with the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            To a third party in the event of any reorganization, merger, sale,
            joint venture, assignment, transfer or other disposition of all or
            any portion of our business, assets or stock (including in
            connection with any bankruptcy or similar proceeding).
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            As we believe to be necessary or appropriate: (a) under applicable
            law, including laws outside your country of residence; (b) to comply
            with legal process; (c) to respond to requests from public or
            government authorities, including public or government authorities
            outside your country of residence; (d) to enforce our terms and
            conditions; (e) to protect our operations or those of any of our
            related legal entities; (f) to protect our rights, privacy, safety
            or property, or that of our related legal entities, service
            providers, members, you or others, including through credit-fraud
            protection and risk reduction; (g) at our discretion under emergency
            circumstances; or (h) to allow us to pursue available remedies or
            limit the damages that we may sustain.
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        <b>E. Collection, Use and Disclosure of Other Information.</b>
      </p>
      <br />
      <p dir="ltr">
        Other Information We May Collect
      </p>
      <br />
      <p dir="ltr">
        "Other Information" is any information that does not reveal a person’s
        specific identity, such as:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Browser information.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Server log files.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Statistics concerning messages sent or received through the
            Platform, such as the length of such messages, the IP Addresses
            involved and other technical parameters.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Information collected through cookies, pixel tags and other
            technologies.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Browsing analytics.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Demographic information.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Aggregated information.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Physical location data.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Information relating to a corporation, partnership or other business
            entity.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        How We May Collect Other Information
      </p>
      <br />
      <p dir="ltr">
        We and our third-party service providers may collect Other Information
        in a variety of ways, including:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Through your device: Certain information is collected automatically
            through your device, such as your operating system name and version,
            device manufacturer and model, device identifiers, language, and the
            name and version of the software application you are using.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Through server log files: Your "IP Address" is a number that is
            automatically assigned to the device that you are using by your
            Internet Service Provider (ISP). An IP Address is identified and
            logged automatically in our server log files whenever a user uses
            the Platform, along with the time of the use and the pages or areas
            visited within the Platform. Collecting IP Addresses is standard
            practice on the Internet and is done automatically by many websites.
            We and our third-party service providers use IP Addresses for
            purposes such as helping to calculate usage levels of the Platform,
            diagnose server problems and administer the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Using cookies: Cookies allow a server to transfer data to a device
            for recordkeeping and other purposes. We and our third-party service
            providers use cookies and other technologies to, among other things,
            better serve you with more tailored information and facilitate your
            ongoing access to and use of the Platform, as well as for online
            tracking purposes. If you do not want information collected through
            the use of cookies, there is a simple procedure in most devices that
            allows you to decline the use of cookies. If you choose to decline
            cookies, some or all of the features, functionality and promotions
            available through the Platform may not be available to you.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Using pixel tags and other similar technologies: Pixel tags (also
            known as web beacons and clear GIFs) and other similar technologies
            may be used in connection with some pages and areas of the Platform
            and HTML-formatted e-mail messages to, among other things, track the
            actions of users of the Platform and e-mail recipients, measure the
            success of our marketing campaigns and compile statistics about
            usage and response rates of the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Physical Location: We may collect the physical location of your
            device by, for example, using satellite, cell phone tower or WiFi
            signals. We may use your device’s physical location to provide you
            with personalized location-based services and content. We may also
            share your device’s physical location, combined with information
            about what advertisements you viewed and other information we
            collect, with our marketing partners to enable them to provide you
            with more personalized content and to study the effectiveness of
            advertising campaigns. In some instances, you may be permitted to
            allow or deny such uses and/or sharing of your device’s location,
            but if you choose to deny such uses and/or sharing, we and/or our
            marketing partners may not be able to provide you with the
            applicable personalized services and content.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            From you: We may collect demographic information such as, for
            example, your age, occupation and family status, as well as other
            information when you voluntarily provide such information to us. We
            may also collect such information about you from third parties.
            Unless combined with Personal Information, this information does not
            personally identify you or any other user of the Platform.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            By aggregating information: Aggregated Personal Information does not
            personally identify you or any other user of the Platform. For
            example, we may aggregate Personal Information to calculate the
            percentage of our users who have a particular telephone area code.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        How We May Use and Disclose Other Information
      </p>
      <br />
      <p dir="ltr">
        Because Other Information does not personally identify you, we may use
        and disclose Other Information for any purpose. In some instances, we
        may combine Other Information with Personal Information (such as
        combining your zip code with your name). If we combine any Other
        Information with Personal Information, the combined information will be
        treated by us as Personal Information as long as it is so combined.
      </p>
      <br />
      <p dir="ltr">
        <b>F. Third Party Advertisers.</b>
      </p>
      <br />
      <p dir="ltr">
        We may use third-party advertising companies to serve ads when you visit
        the Platform. Please note that these companies may use information about
        your visit to the Platform to provide ads about goods and services that
        may be of interest to you. In the course of serving ads to the Platform,
        these companies may place or recognize a unique cookie on your browser.
        If you would like more information about this practice, and to know your
        choices about not having this information used by these companies,
        please visit
        <a
          href="http://networkadvertising.org/optout_nonppii.asp"
          target="_blank"
        >
          http://networkadvertising.org/optout_nonppii.asp
        </a>
        .
      </p>
      <br />
      <p dir="ltr">
        <b>G. Social Plugins.</b>
      </p>
      <br />
      <p dir="ltr">
        Certain areas of the Platform may make available certain third-party
        social media functionality, such as the Facebook "Like"
        button ("Social Plugins"). Such Social Plugins, and any third-party
        collection, use and disclosure of any information through or in
        connection with such Social Plugins, are subject to the applicable third
        party’s privacy policies, which may differ from this Privacy Policy. For
        example, a Social Plugin provider may be able to collect information
        about you through such Social Plugin, including your activity on the
        Platform, and such provider may disclose information on the applicable
        social network about your use of the Platform. Social Plugins may also
        employ unique identifiers that allow your activity to be monitored
        across multiple websites by such providers and other third parties,
        including for purposes of delivering targeted advertising to you.
      </p>
      <br />
      <p dir="ltr">
        <b>H. Do Not Track Signals.</b>
      </p>
      <br />
      <p dir="ltr">
        We note that the Platform will continue to operate as described in this
        Privacy Policy, whether or not a "Do Not Track" signal or similar
        mechanism is received from your browser.
      </p>
      <br />
      <p dir="ltr">
        <b>I. Security.</b>
      </p>
      <br />
      <p dir="ltr">
        We seek to use within Company reasonable organizational, technical and
        administrative measures to protect Personal Information under our
        control. Unfortunately, no data transmission over the Internet or data
        storage system is guaranteed to be 100% secure. Please also be aware
        that we may use third-party cloud service providers that provide
        hosting, data storage and other services pursuant to standard terms and
        conditions that are generally non-negotiable; accordingly, although we
        seek to conduct reasonable due diligence on such service providers, we
        may be unable to impose contractual obligations on them with respect to
        the measures they use to protect Personal Information. We are not
        responsible for the data security measures of any third parties.
      </p>
      <br />
      <p dir="ltr">
        If you have reason to believe that your interaction with us is no longer
        secure (for example, if you feel that the security of any account you
        might have with us has been compromised), please immediately notify us
        of the problem by contacting us via Company’s Contact page.
      </p>
      <br />
      <p dir="ltr">
        <b>J. Choices and Access.</b>
      </p>
      <br />
      <p dir="ltr">
        We give you many choices regarding our use and disclosure of your
        Personal Information, including that of EU, UK, and Swiss individuals. You
        may opt-out from:
      </p>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Receiving communications from us based upon your Personal
            Information: If you no longer want to receive marketing-related
            e-mails from us going-forward, you may opt out of receiving such
            communications by contacting us via Company’s Contact page, emailing
            us at privacy@harryfox.com or by clicking the “unsubscribe” link
            that is included at the end of marketing-related e-mails. Please
            note that commercial e-mails regarding opportunities for publishers
            to participate in licensing arrangements do not constitute
            “marketing-related” e-mails.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Our sharing of your Personal Information with related legal entities
            for their marketing purposes: If you would prefer that we do not
            share your Personal Information collected pursuant to this Privacy
            Policy with our related legal entities for their marketing purposes,
            you may opt out of such sharing by contacting us via Company’s
            Contact page, or by emailing us at privacy@harryfox.com.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Our sharing of your Personal Information with third parties who are
            not acting as our agents: If you would prefer that we do not share
            your Personal Information collected pursuant to this Privacy Policy
            with third parties who are not acting as our agents, you may opt out
            of such sharing by contacting us via Company’s Contact page, or by
            emailing us at privacy@harryfox.com.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        We will try to comply with your request(s) as soon as reasonably
        practicable. Please note that if you opt out of our sharing of Personal
        Information with related legal entities for their marketing purposes, as
        described above, we will not be able to remove your Personal Information
        from the databases of any such related legal entities with which we have
        already shared your Personal Information (i.e., to which we have already
        provided your Personal Information as of the date that we implement your
        opt-out request). Please also note that if you opt out of receiving
        marketing-related communications from us, we may still send to you
        non-marketing related communications, such as those about your account
        or our ongoing business relations. Finally, please note that if you
        choose to opt out of our sharing your Personal Information with third
        parties who are not our agents, we may not be able to provide you with
        services for which you provided us your Personal Information.
      </p>
      <br />
      <p dir="ltr">
        How You Can Access Your Personal Information
      </p>
      <br />
      <p dir="ltr">
        You may update and correct certain account information contacting us via
        Company’s Contact page or by emailing us at privacy@harryfox.com. If you
        wish to delete your account (by email or otherwise), please note that we
        may retain certain information as required by law or for our legitimate
        business purposes.
      </p>
      <br />
      <p dir="ltr">
        <b>Cookies</b>
      </p>
      <p dir="ltr">
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually adjust your browser settings to remove or reject browser
        cookies. Please note that removing or rejecting cookies could affect the
        availability and functionality of our Services.
      </p>
      <br />
      <p dir="ltr">
        <b>K. No Use of the Platform by Minors.</b>
      </p>
      <br />
      <p dir="ltr">
        The Platform is not directed to individuals under the age of thirteen
        (13), nor are such individuals permitted to use the Platform;
        accordingly, we request that such individuals do not provide Personal
        Information in connection with the Platform. If you are the parent or
        guardian of such an individual who has disclosed Personal Information to
        us in connection with the Platform, please contact us via Company’s
        Contact page, and we will exercise commercially reasonable efforts to
        remove such information from our databases and storage systems; we are
        not liable, however, for any consequences of such information, including
        any delay or failure to remove such information.
      </p>
      <br />
      <p dir="ltr">
        <b>L. Retention Period.</b>
      </p>
      <br />
      <p dir="ltr">
        We will retain your Personal Information for the period necessary to
        fulfill the purposes outlined in this Privacy Policy, unless a longer
        retention period is required or allowed by law.
      </p>
      <br />
      <p dir="ltr">
        <b>M. Your California Privacy Rights.</b>
      </p>
      <br />
      <p dir="ltr">
        California Civil Code Section 1798.83 permits customers of Company who
        are California residents to request certain information regarding
        Company’s disclosure of personally identifiable information to third
        parties for their direct marketing purposes. To make such a request,
        please write us at:
      </p>
      <p dir="ltr">
        The Harry Fox Agency LLC<br />
        ATTN: Legal<br />
        40 Wall Street, 6th Floor<br />
        New York, NY 10005<br />
        Toll Free Telephone: <a href="tel:1-833-305-6136">1-833-305-6136</a
        ><br />
        Email: <a href="mailto:privacy@harryfox.com">privacy@harryfox.com</a>
      </p>

      <br />

      <a
        class="button default"
        href="javascript:;"
        @click="showCaliforniaPrivacyRightsForm"
        >Personal Information Request Form</a
      ><br />

      <br />

      <p>
        Under the California Consumer Protection Act, California residents have
        certain rights:
      </p>

      <p>
        <i><strong>Right to Request Deletion of Personal Information</strong></i
        >, except where it is necessary for the Company to retain the
        information for certain business purposes.
      </p>

      <p>
        <i><strong>Right to Request Personal Information Collected</strong></i>
        (no more than two times in a 12-month period)<br />
        (1) The categories of personal information it has collected about that
        consumer.<br />
        (2) The categories of sources from which the personal information is
        collected.<br />
        (3) The business or commercial purpose for collecting or selling
        personal information.<br />
        (4) The categories of third parties with whom the business shares
        personal information.<br />
        (5) The specific pieces of personal information it has collected about
        that consumer.
      </p>

      <p></p>

      <p>
        California residents may make such requests by contacting Company at the
        above contact information or by submitting the request through the
        Personal Information Request Form.
      </p>

      <p>
        In addition, Company will not discriminate against you because you
        exercise your rights. For example, Company will not deny you goods or
        services or charge you a different price or rate for goods or services
        if you make an access or deletion.
      </p>

      <p>
        <i
          ><strong>Categories of Information that has been collected</strong></i
        >
      </p>

      <p>
        Within the last 12 months, we have collected the following personal
        information:<br />
        (1) Identifiers (e.g. name, mailing address, email address, phone
        number, tax identification number, bank account number);<br />
        (2) Commercial information; and<br />
        (3) Internet or other electronic network activity.
      </p>

      <p>
        <i
          ><strong
            >Categories of Information that has been disclosed for a business
            purpose</strong
          ></i
        >
      </p>

      <p>
        Within the last 12 months, we have disclosed the following personal
        information:<br />
        (1) Identifiers (e.g. name, mailing address, email address, phone
        number, internal account identifier);<br />
        (2) Commercial information; and<br />
        (3) Electronic network activity information.
      </p>

      <p>Company does not sell your personal information.</p>

      <br />
      <p dir="ltr">
        <b>N. Data Privacy Framework</b>
      </p>
      <br />
      <p dir="ltr">
        Company complies with the EU-U.S. Data Privacy Framework (“EU-U.S.
        DPF”), the UK Extension to the EU-U.S. DPF (“UK Extension”), and the
        Swiss-U.S. Data Privacy Framework (“Swiss-U.S. DPF”) as set forth by the
        U.S. Department of Commerce. Company has certified to the U.S.
        Department of Commerce that it adheres to the EU-U.S. DPF Principles
        with regard to the processing of personal data received from the
        European Union in reliance on the EU-U.S. DPF and from the United
        Kingdom (and Gibraltar) in reliance on the UK Extension. Company has
        certified to the U.S. Department of Commerce that it adheres to the
        Swiss-U.S. DPF Principles with regard to the processing of personal data
        received from Switzerland in reliance on the Swiss-U.S. DPF. If there is
        any conflict between the terms in this privacy policy and the EU-U.S. DPF 
        Principles, the UK Extension Principles, and/or the Swiss-U.S. DPF 
        Principles, the Principles shall govern. To learn more about the Data 
        Privacy Framework (DPF) program, and to view our certification, please visit
        <a href="https://www.dataprivacyframework.gov/"
          >https://www.dataprivacyframework.gov/</a
        >.
      </p>
      <p dir="ltr">
        We remain liable if our third-party service providers process Personal
        Information, including that of EU, UK, and Swiss individuals, on our behalf
        in a manner inconsistent with the DPF Principles, unless we are not
        responsible for the event giving rise to the damage.
      </p>
      <p dir="ltr">
        The Federal Trade Commission has jurisdiction over Company’s compliance
        with the EU-U.S. DPF, the UK Extension, and the Swiss-U.S. DPF.
      </p>
      <p dir="ltr">
        In certain situations, Company may be required to disclose Personal
        Information in response to lawful requests by public authorities,
        including to meet national security or law enforcement requirements.
      </p>
      <p dir="ltr">
        In compliance with the EU-U.S. DPF, the UK Extension, and the Swiss-U.S.
        DPF, Company commits to resolve DPF Principles-related complaints about
        our collection and use of your personal information. EU, UK, and Swiss
        individuals with inquiries or complaints regarding our handling of
        personal data received in reliance on the EU-U.S. DPF, the UK Extension,
        and the Swiss-U.S. DPF should first contact Company at:
      </p>
      <p dir="ltr">
        The Harry Fox Agency LLC<br />
        ATTN: Data Privacy Framework Notice<br />
        40 Wall Street, 6th Floor<br />
        New York, NY 10005<br />
        Email:
        <a href="mailto:privacy@harryfox.com"
          >privacy@harryfox.com</a
        >
      </p>
      <p dir="ltr">
        As explained in the Annex I of the DPF Principles, binding arbitration
        is available, including to EU, UK, and Swiss individuals, in certain
        circumstances to address complaints regarding DPF compliance not
        resolved by any of the other DPF mechanisms. For additional information,
        please visit:
        <a
          href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2"
          >https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2</a
        >
      </p>
      <p dir="ltr">
        In compliance with the EU-U.S. DPF, the UK Extension, and the Swiss-U.S.
        DPF, Company commits to refer unresolved complaints concerning our
        handling of personal data received in reliance on the EU-U.S. DPF, the
        UK Extension, and the Swiss-U.S. DPF to JAMS, an alternative dispute
        resolution provider based in the United States. If you do not receive
        timely acknowledgment of your DPF Principles-related complaint from us,
        or if we have not addressed your DPF Principles-related complaint to
        your satisfaction, please visit
        <a href="https://www.jamsadr.com/DPF-Dispute-Resolution"
          >https://www.jamsadr.com/DPF-Dispute-Resolution</a
        >
        for more information or to file a complaint. The services of JAMS are
        provided at no cost to you.
      </p>
      <br />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CaliforniaPrivacyRights from "@/components/CaliforniaPrivacyRights.vue";
import Contact from "@/views/Contact.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Privacy Policy",
      meta: [
        {
          name: "description",
          content: "HFA Online Privacy Policy"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  methods: {
    showCaliforniaPrivacyRightsForm: function() {
      this.$modal.show(
        CaliforniaPrivacyRights,
        {},
        {
          height: "auto",
          width: "80%",
          classes: "v--modal california-form-modal"
        },
        {
          "before-open": function() {
            document.documentElement.style.overflow = "hidden";
          },
          "before-close": function() {
            document.documentElement.style.overflow = "visible";
          }
        }
      );
    },
    showContactForm: function() {
      this.$modal.show(
        Contact,
        {},
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  }
};
</script>

<style lang="scss">
#privacy-policy {
  .content {
    margin: 75px 50px 20vh 0;

    .last-updated {
      float: right;
    }
    a {
      text-decoration: underline;
    }
    h2 {
      margin-bottom: 50px;
    }
  }
}
</style>
