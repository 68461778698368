<template>
  <div id="contact">
    <div class="close" @click="$emit('close')">
      <img src="@/assets/delete.png" alt="close" />
    </div>

    <div class="loading" v-if="isLoading">
      <span>Loading</span>
      <img src="@/assets/loading.svg" alt="loading" />
    </div>
    <div class="success" v-if="isSuccess">
      Your message was successfully sent.
    </div>
    <!-- <div class="errors" v-if="errors.length">{{ errors }}</div> -->
    <div class="title">Contact</div>
    <form @submit.prevent="validateForm" id="contact-form" novalidate>
      <div class="basic-data">
        <InputField
          name="firstName"
          :value="user.firstName"
          @update:value="user.firstName = $event"
          label="First Name"
          :required="true"
          :errors="errors"
        />
        <InputField
          name="lastName"
          :value="user.lastName"
          @update:value="user.lastName = $event"
          label="Last Name"
          :required="true"
          :errors="errors"
        />
        <InputField
          name="email"
          :value="user.email"
          @update:value="user.email = $event"
          label="Email"
          :required="true"
          :errors="errors"
        />

        <div class="input-field" :class="{ 'has-error': hasError('service') }">
          <select name="service" id="service-select" v-model="user.service">
            <option value="">What service is your question about?*</option>
            <option v-for="item in services" :key="item" :value="item">{{
              item
            }}</option>
          </select>
          <div class="errors">
            <ul>
              <li
                v-for="error in errors.filter(
                  error => error.field == 'service'
                )"
                :key="error.field"
              >
                * {{ error.error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="message" :class="{ 'has-error': hasError('message') }">
        <textarea name="message" id="message" v-model="user.message"></textarea>
      </div>
      <div class="buttons">
        <button type="submit" class="button primary shadow">SUBMIT</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import InputField from "@/components/InputField.vue";
import UsefulLib from "@/services/useful-lib.js";

export default {
  name: "Contact",
  components: {
    InputField
  },
  props: ["text"],
  data: function() {
    return {
      isLoading: false,
      isSuccess: false,
      isMobile: false,
      errors: [],
      user: {
        firstName: null,
        lastName: null,
        email: null,
        message: null,
        service: "",
        isClient: "n/a"
      },
      services: ["eMech", "Songfile", "HFA General", "Rumblefish General"]
    };
  },
  methods: {
    deviceType: () => UsefulLib.detectDeviceType(),
    hasError: function(name) {
      return this.errors.filter(error => error.field == name).length;
    },
    toggleDropdown: function(e) {
      e.path
        .find(node => /dropdown/.test(node.className))
        .classList.toggle("open");
    },
    selectService: function(item) {
      this.user.service = item;
    },
    validateForm: function(e) {
      this.errors = [];

      for (let key in this.user) {
        let value = this.user[key];

        if (!value) {
          this.errors.push({ field: key, error: "Field Required" });
        }
        if (
          key == "email" &&
          !this.errors.find(error => error.field == "email")
        ) {
          let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!reg.test(value)) {
            this.errors.push({ field: key, error: "Email is invalid" });
          }
        }
      }

      if (!this.errors.length) {
        this.isLoading = true;
        let $this = this;

        let user = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          message: this.user.message,
          service: this.user.service
        };
        let url = process.env.VUE_APP_API_URL.replace("users", "contact");

        // console.log(user, url);
        axios
          .post(url, user)
          .then(function() {
            $this.isLoading = false;
            $this.isSuccess = true;
          })
          .catch(function(error) {
            console.log(error);
            $this.isLoading = false;
            alert(
              "Sorry, there was a problem sending your message. Please try again later."
            );
          });
      }

      e.preventDefault();
    }
  },
  mounted: function() {
    if (window.innerWidth <= 1223) {
      this.isMobile = true;
    }
    if (this.text) {
      this.user.message = this.text;
    }
  }
};
</script>

<style lang="scss">
#contact {
  position: relative;
  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
  padding: 8px;

  @media (min-width: $desktop) {
    padding: 50px;
    width: 75vw;
    height: auto;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .loading,
  .success {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    font-size: 1.5em;
    text-align: center;

    &.loading img {
      width: 100px;
    }
  }

  .title {
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-size: 32px;
    margin: 0 0 20px;
  }

  #contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input-field {
      select {
        width: 100%;
      }

      &.has-error {
        select {
          border-color: red !important;
        }
      }
    }

    .basic-data {
      width: 35%;
      display: flex;
      flex-direction: column;

      .box {
        border: 1px solid #ccc;
        padding: 1em;
        font-size: 1.1em;
        text-align: center;
        margin: 10px 0;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
      #service-select {
        border: 1px solid #ccc;
        background-color: #fff;
        font-size: 1.1em;
        font-family: "Abel", sans-serif;
        padding: 1em;
      }
      .dropdown {
        padding: 0;
        cursor: pointer;
        position: relative;

        .label {
          padding: 1em 0;
          position: relative;

          .arrow {
            position: absolute;
            top: 1em;
            right: 0;
            background-image: url("../assets/arrow.png");
            background-size: 120%;
            background-position: center center;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
        input {
          top: 0;
          left: 0;
          z-index: -1;
          position: absolute;
        }
        .list {
          position: absolute;
          width: 100%;
          background-color: #fff;
          border: 1px solid #ccc;
          border-top: 0;
          left: -1px;
          // padding-top: 1em;
          z-index: 10;
          display: none;
          height: 150px;
          overflow: auto;

          .item {
            border-top: 1px solid #ccc;
            padding: 0.3em;

            &:hover {
              background-color: #f6f6f6;
            }
          }
        }
        input:focus + .list,
        &.open .list {
          display: block;
        }
        .errors {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          color: red;
          transform: translateY(100%);
          font-size: 0.8em;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: block;
            }
          }
        }
        &.has-error {
          border-color: red;

          .errors {
            display: block;
          }
        }
      }
    }
    .message {
      width: 55%;

      textarea {
        border: 1px solid #ccc;
        resize: none;
        width: calc(100% - 1em - 2px);
        height: 120px;
        padding: 0.5em;
        font-family: "Abel", sans-serif;
        font-size: 1em;
        letter-spacing: 1px;
        outline: none;
        margin-top: 20px;
      }

      &.has-error {
        textarea {
          border-color: red;
        }
      }
    }
    .buttons {
      width: 100%;
      margin-top: 20px;
      text-align: right;

      button {
        font-size: 1.4em;
        width: 200px;
      }
    }

    .basic-data,
    .message,
    .buttons {
      width: 100%;
    }

    @media (min-width: $desktop) {
      .basic-data {
        width: 35%;
      }
      .message {
        width: 55%;

        textarea {
          height: calc(100% - 1em);
          margin-top: 0;
        }
      }
      .buttons {
        width: 100%;
      }
    }
  }
}
</style>
